
























































































































































































































































































































































































import { Questionnaire, QuestionType } from "@/data/models/Questionnaires";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { saveAs } from "file-saver";
import { readFileAsDataUrlAsync } from "@/lib/readFile";
import DatePicker from "./DatePicker.vue";
import SubArrayForm from "@/components/SubArrayForm.vue";
import FileInput from "@/components/FileInput.vue";
import { toArray, toMap } from "@/lib/answers";
@Component({
  components: {
    DatePicker,
    SubArrayForm,
    FileInput
  }
})
export default class DynamicForm extends Vue {
  @Prop({ default: null })
  public questionnaire!: Questionnaire | null;

  @Prop()
  public answers!: any[];

  @Prop()
  public showPreview!: any;

  @Prop()
  public questionnaireTitle!: string;

  private answersInternal: any = null;

  @Prop({ default: () => false })
  public readonly!: boolean;

  @Prop({ default: () => null })
  public submissionId!: string;

  requiredSelect(val: any) {
    const a = this.answers;
    const q = this.questionnaire?.questions;
    if (val && Array.isArray(val) && val.length > 0 && val[0] != null) {
      return true;
    }
    return "Required";
  }
  requiredBool(val: any) {
    if (val === null || val === undefined) {
      return "Required";
    }
    return true;
  }
  mounted() {
    this.onQuestChanged();
  }

  private resetAnswer(q: any) {
    const def = {
      responseText: null,
      responseBool: null,
      responseDate: null,
      pdpAchieved: null,
      pdpWhen: null,
      pdpWhom: null,
      comments: null,
      options: [],
      tuples: []
    };
    const ans = this.answersInternal[q.id];
    if (
      ans.responseText ||
      ans.responseBool !== null ||
      ans.responseDate ||
      ans.pdpAchieved ||
      ans.pdpWhen ||
      ans.pdpWhom ||
      ans.options?.length > 0
    ) {
      Object.assign(this.answersInternal[q.id], def);
    }
  }
  showBoundTo(q: any) {
    if (!q.boundTo || !q.boundTo.boundQuestion) {
      this.resetAnswer(q);
      return false;
    }
    const res =
      ((q.boundTo.boundQuestion.type === "select" ||
        q.boundTo.boundQuestion.type === "multiselect") &&
        q.boundTo.options &&
        q.boundTo.options.some((opt: any) => {
          const optionId = opt.id;
          return this.hasSelectedOption(q.boundTo.boundQuestionId, optionId);
        })) ||
      (q.boundTo.boundQuestion.type === "boolean" &&
        this.answersInternal[q.boundTo.boundQuestionId] &&
        q.boundTo.booleanOption ===
          this.answersInternal[q.boundTo.boundQuestionId].responseBool);
    if (!res) {
      this.resetAnswer(q);
    }
    return res;
  }
  showOther(q: any) {
    return this.hasSelectedOption(q.id, "other");
  }
  hasSelectedOption(qId: string, option: string) {
    const answers = this.answersInternal;
    const question = this.questionnaire?.questions.filter(q => q.id === qId)[0];
    return (
      answers &&
      answers[qId] &&
      answers[qId].options &&
      (answers[qId].options.filter((o: any) => o.id === option).length > 0 ||
        (question?.showOtherOption && answers[qId].responseText))
    );
  }
  @Watch("questionnaire")
  onQuestChanged() {
    this.onAnswersChanged();
  }

  private async setFile(file: File, ans: any) {
    const res = await readFileAsDataUrlAsync(file);
    ans.file = {
      name: file.name,
      base64Contents: res
    };
  }
  @Watch("answersInternal", { immediate: true, deep: true })
  private onAnswersInternalChanged() {
    if (this.answersInternal) {
      this.$emit("update:answers", toArray(this.answersInternal));
    }
  }

  @Watch("answers", { immediate: true, deep: false })
  private onAnswersChanged() {
    if (!this.questionnaire || !this.answers || !this.questionnaire.questions) {
      return;
    }

    if (
      this.answersInternal &&
      Object.keys(this.answersInternal).length === this.answers.length
    ) {
      return;
    }
    const ans: any = {};
    const answers = toMap(this.answers);
    for (const question of this.questionnaire.questions) {
      if (!ans[question.id]) {
        if (answers[question.id]) {
          ans[question.id] = {
            id: answers[question.id].id,
            questionId: question.id,
            responseText: answers[question.id].responseText,
            responseBool: answers[question.id].responseBool,
            responseDate: answers[question.id].responseDate,
            pdpAchieved: answers[question.id].pdpAchieved,
            pdpWhen: answers[question.id].pdpWhen,
            pdpWhom: answers[question.id].pdpWhom,
            comments: answers[question.id].comments,
            options:
              question.showOtherOption && answers[question.id].responseText
                ? [
                    ...answers[question.id].options,
                    { id: "other", value: "Other" }
                  ]
                : answers[question.id].options,
            tuples: answers[question.id].tuples,
            file: answers[question.id].file
          };
        } else {
          ans[question.id] = this.generateAnswer(question.id);
        }
      }
    }
    this.answersInternal = ans;
  }

  private generateAnswer(
    id: string
  ): {
    questionId: string;
    responseText: string | null;
    responseBool: boolean | null;
    responseDate: string | null;
    pdpAchieved: boolean | null;
    pdpWhen: string | null;
    pdpWhom: string | null;
    comments: string | null;
    options: any[];
    tuples: any[];
    file: any;
  } {
    return {
      questionId: id,
      responseText: null,
      responseBool: null,
      responseDate: null,
      pdpAchieved: null,
      pdpWhen: null,
      pdpWhom: null,
      comments: null,
      options: [],
      tuples: [],
      file: null
    };
  }

  async download(name: string) {
    try {
      this.downloadBlob(await this.$service.downloadFile(name), name);
    } catch (e) {
      console.error(e);
    }
  }

  private async downloadAnswers() {
    try {
      saveAs(
        await this.$service.downloadQuestionnaireAnswers(this.submissionId),
        `${this.questionnaire?.name}.xlsx`
      );
    } catch (e) {
      console.error(e);
    }
  }

  private downloadBlob(blob: Blob, name: string) {
    saveAs(
      blob,
      name
        .split("-")
        .slice(5)
        .join("-")
    );
  }
}
