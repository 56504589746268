var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-pa-md"},[_c('q-card',{ref:"form",attrs:{"flat":"","bordered":""}},[(_vm.fullName)?_c('q-card-section',[_c('div',{staticClass:"row text-h6 q-px-md"},[_c('div',{staticClass:"col"},[_vm._v("Service Wizard for Beneficiary : "+_vm._s(_vm.fullName))]),_c('div',{staticClass:"col"},[_c('q-btn',{staticClass:"float-right",attrs:{"flat":"","color":"primary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Case Management")])],1)])]):_vm._e(),_c('q-stepper',{attrs:{"flat":"","bordered":"","vertical":"","color":"primary","animated":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('q-step',{attrs:{"name":1,"title":"Select service","icon":"settings","done":_vm.step > 1}},[(_vm.item && _vm.projectNames.length > 0)?_c('div',{},[_c('q-separator',{staticStyle:{"visibility":"hidden"},attrs:{"spaced":""}}),_c('div',{staticClass:"row"},[(!_vm.selectedHouseholdAction)?_c('div',{staticClass:"col-md-6 q-pb-md q-pr-sm"},[_c('q-card',{attrs:{"flat":"","bordered":""}},[_c('q-card-section',[_c('div',{staticClass:"text-h6"},[_vm._v("Select new action")])]),_c('q-card-section',{staticClass:"q-py-none"},[(_vm.userSubmissions !== null)?_c('div',[_c('q-select',{attrs:{"filled":"","display-value":_vm.projectNames.length < 2
                          ? _vm.projectNames[0].label
                          : _vm.projectNames.label,"options":_vm.projectNames,"option-value":function (i) { return i.value; },"option-label":function (i) { return i.label; },"label":"Select Project","rules":[_vm.$rules.required]},model:{value:(_vm.projectTitle),callback:function ($$v) {_vm.projectTitle=$$v},expression:"projectTitle"}})],1):_vm._e(),_c('div',[_c('date-picker',{attrs:{"label":"Start Date","rules":[_vm.$rules.required]},model:{value:(_vm.item.startDate),callback:function ($$v) {_vm.$set(_vm.item, "startDate", $$v)},expression:"item.startDate"}})],1),_c('div',[_c('entity-select',{ref:"select",attrs:{"isRequired":true,"filled":"","provider":_vm.ActionTypesProvider,"label":"Service Type","optionValue":function (i) { return (i ? i.id : null); },"optionLabel":function (i) { return (i && i.id ? ("" + (i.name)) : ''); },"rules":[_vm.$rules.required],"oDataArgs":{
                        filterExpression: _vm.actionTypeFilter
                      }},on:{"input":function (e) { return (_vm.selectedAction = e); }},model:{value:(_vm.ActionTypesModel),callback:function ($$v) {_vm.ActionTypesModel=$$v},expression:"ActionTypesModel"}})],1),_c('div',[(
                        _vm.ActionTypesModel &&
                          _vm.ActionTypesModel.name === 'Legal Services'
                      )?_c('entity-select',{ref:"select",attrs:{"isRequired":true,"filled":"","provider":_vm.provider,"label":"Service","value":_vm.item.actionActionTypeId,"optionValue":function (i) { return (i ? i.id : null); },"optionLabel":function (i) { return i && i.action
                            ? ((i.action.name) + " (" + (i.actionType.name) + ")")
                            : ''; },"rules":[_vm.$rules.required],"oDataArgs":{
                        filterExpression: ("(ActionTypeId eq " + (_vm.ActionTypesModel.id) + " " + (this.filteredServices) + " and QuestionnaireId eq null)")
                      }},on:{"update:value":function($event){return _vm.$set(_vm.item, "actionActionTypeId", $event)}},model:{value:(_vm.item.actionActionType),callback:function ($$v) {_vm.$set(_vm.item, "actionActionType", $$v)},expression:"item.actionActionType"}}):_vm._e(),(
                        _vm.ActionTypesModel &&
                          _vm.ActionTypesModel.name !== 'Legal Services'
                      )?_c('entity-select',{ref:"select",attrs:{"isRequired":true,"filled":"","provider":_vm.provider,"label":"Service","value":_vm.item.actionActionTypeId,"optionValue":function (i) { return (i ? i.id : null); },"optionLabel":function (i) { return i && i.action
                            ? ((i.action.name) + " (" + (i.actionType.name) + ")")
                            : ''; },"rules":[_vm.$rules.required],"oDataArgs":{
                        filterExpression: ("(ActionTypeId eq " + (_vm.ActionTypesModel.id) + " " + (this.filteredServices) + ")")
                      }},on:{"update:value":function($event){return _vm.$set(_vm.item, "actionActionTypeId", $event)}},model:{value:(_vm.item.actionActionType),callback:function ($$v) {_vm.$set(_vm.item, "actionActionType", $$v)},expression:"item.actionActionType"}}):_vm._e()],1),(
                      _vm.selectedAction &&
                        _vm.selectedAction.name === 'Legal Services'
                    )?_c('div',{staticClass:"col-12 col-md-6 q-pb-md"},[_c('q-select',{attrs:{"label":"Legal Case Status","options":['Open', 'Closed'],"filled":"","dense":""},model:{value:(_vm.item.status),callback:function ($$v) {_vm.$set(_vm.item, "status", $$v)},expression:"item.status"}})],1):_vm._e()])],1)],1):_vm._e(),(_vm.houseHoldActions && _vm.houseHoldActions.length > 0)?_c('div',{staticClass:"col-md-6 q-pb-md q-pl-sm"},[_c('q-card',{attrs:{"flat":"","bordered":""}},[_c('q-card-section',[_c('div',{staticClass:"text-h6"},[_vm._v("Select from pre-existing")])]),_c('q-card-section',{staticClass:"q-pt-none"},[_c('q-select',{attrs:{"filled":"","options":_vm.houseHoldActions,"label":"Action Type","optionValue":function (i) { return i &&
                        i.actionActionType &&
                        i.actionActionType.actionType
                          ? i.actionActionType.actionType.id
                          : null; },"optionLabel":function (i) { return i &&
                        i.actionActionType &&
                        i.actionActionType.actionType
                          ? ((i.actionActionType.action.name) + " \n                        - " + (i.actionActionType.actionType.name) + " \n                        - " + (i.project ? i.project.title : '') + "\n                        - " + (i.startDate ? i.startDate.split('T')[0] : ''))
                          : ''; }},on:{"input":function (e) { return _vm.bindHouseholdAction(e); }},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.selectedHouseholdAction)?_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":"close"},on:{"click":function($event){$event.stopPropagation();return _vm.clearSelectedAction($event)}}}):_vm._e()]},proxy:true}],null,false,2097758036),model:{value:(_vm.selectedHouseholdAction),callback:function ($$v) {_vm.selectedHouseholdAction=$$v},expression:"selectedHouseholdAction"}}),(
                      _vm.selectedHouseholdAction &&
                        _vm.item.actionActionType.actionType &&
                        _vm.item.actionActionType.actionType.name ===
                          'Legal Services'
                    )?_c('div',{staticClass:"col-12 col-md-6 q-pt-md"},[_c('q-select',{attrs:{"label":"Legal Case Status","options":['Open', 'Closed'],"filled":"","dense":""},model:{value:(_vm.item.status),callback:function ($$v) {_vm.$set(_vm.item, "status", $$v)},expression:"item.status"}})],1):_vm._e()],1)],1)],1):_vm._e()]),(
              _vm.item &&
                _vm.item.actionActionType &&
                _vm.item.actionActionType.action &&
                _vm.item.actionActionType.action.hasMultiples
            )?_c('div',{staticClass:"col-12"},[_c('q-input',{attrs:{"filled":"","label":"Description","hint":" "},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}})],1):_vm._e(),(
              _vm.item.actionActionType &&
                _vm.item.actionActionType.action.assignCompanies
            )?_c('div',{staticClass:"col-12"},[_c('q-checkbox',{attrs:{"label":"Company(-ies) of HR360 Network"},model:{value:(_vm.hasHrCompanies),callback:function ($$v) {_vm.hasHrCompanies=$$v},expression:"hasHrCompanies"}}),(_vm.hasHrCompanies)?_c('entity-select',{attrs:{"filled":"","multiple":true,"provider":_vm.$service.providers.companies,"label":"Select Companies","optionLabel":function (i) { return (i && i.id ? ("" + (i.name)) : ''); }},model:{value:(_vm.item.companies),callback:function ($$v) {_vm.$set(_vm.item, "companies", $$v)},expression:"item.companies"}}):_c('q-editor',{model:{value:(_vm.item.otherCompanies),callback:function ($$v) {_vm.$set(_vm.item, "otherCompanies", $$v)},expression:"item.otherCompanies"}})],1):_vm._e(),_c('div',{staticClass:"row items-center"},[(!_vm.selectedHouseholdAction)?_c('div',{staticClass:"col-12"},[(
                  _vm.item.actionActionType && _vm.item.actionActionType.questionnaire
                )?_c('dynamic-form',{attrs:{"questionnaireTitle":'Service Questionnaire',"questionnaire":_vm.item.actionActionType.questionnaire,"answers":_vm.answers},on:{"update:answers":function($event){_vm.answers=$event}}}):_vm._e()],1):_vm._e(),(_vm.selectedHouseholdAction)?_c('div',{staticClass:"col-12"},[(
                  _vm.item.questionnaireSubmission &&
                    _vm.item.questionnaireSubmission.questionnaire
                )?_c('dynamic-form',{attrs:{"questionnaireTitle":'Service Questionnaire',"submissionId":_vm.item.questionnaireSubmission
                    ? _vm.item.questionnaireSubmission.id
                    : null,"questionnaire":_vm.item.questionnaireSubmission.questionnaire,"answers":_vm.item.questionnaireSubmission.answers},on:{"update:answers":function($event){return _vm.$set(_vm.item.questionnaireSubmission, "answers", $event)}}}):_vm._e()],1):_vm._e()])],1):_vm._e(),(_vm.projectNames.length == 0)?_c('div',[_vm._v(" The beneficiary needs to participate in a project in order to get services ")]):_vm._e(),_c('q-stepper-navigation',[_c('q-btn',{attrs:{"disable":!_vm.item.actionActionType,"color":"primary","label":_vm.item.actionActionType &&
              !_vm.item.actionActionType.actionType.hasSessions
                ? 'Finish'
                : 'Continue'},on:{"click":_vm.loadSessions}}),_c('q-btn',{staticClass:"q-ml-sm",attrs:{"flat":"","color":"primary","label":"Back"},on:{"click":_vm.goBack}})],1)],1),_c('q-step',{attrs:{"name":2,"title":"Add Session","icon":"add_circle","done":_vm.step > 2}},[_c('div',{staticClass:"row"},[(!_vm.session.id)?_c('div',{staticClass:"col-md-6 q-pb-md q-pr-sm"},[_c('q-card',{attrs:{"flat":"","bordered":""}},[_c('q-card-section',[_c('div',{staticClass:"text-h6"},[_vm._v("Select new session")])]),_c('q-card-section',{staticClass:"q-py-none"},[_c('div',{staticClass:"row q-col-gutter-sm items-center"},[_c('div',{staticClass:"col-12"},[_c('date-picker',{attrs:{"label":"Date","rules":[_vm.$rules.required]},model:{value:(_vm.session.date),callback:function ($$v) {_vm.$set(_vm.session, "date", $$v)},expression:"session.date"}})],1),_c('div',{staticClass:"col-12"},[_c('q-select',{attrs:{"filled":"","options":_vm.purposes,"label":"Purpose","optionValue":function (i) { return (i ? i.id : null); },"optionLabel":function (i) { return (i && i.id ? ("" + (i.value)) : ''); },"rules":[_vm.$rules.required],"emit-value":"","map-options":""},model:{value:(_vm.session.purposeId),callback:function ($$v) {_vm.$set(_vm.session, "purposeId", $$v)},expression:"session.purposeId"}})],1)])])],1)],1):_vm._e(),_c('div',{staticClass:"col-md-6 q-pb-md q-pl-sm"},[_c('q-card',{attrs:{"flat":"","bordered":""}},[_c('q-card-section',[_c('div',{staticClass:"text-h6"},[_vm._v("Select from pre-existing")])]),_c('q-card-section',{staticClass:"q-pt-none"},[_c('q-select',{attrs:{"filled":"","options":_vm.existingActionSessions,"label":"Session","optionLabel":function (i) { return i && i.purpose
                        ? i.purpose.value +
                          ' - ' +
                          i.date.substring(0, i.date.lastIndexOf('T'))
                        : ''; }},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.session)?_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":"close"},on:{"click":function($event){$event.stopPropagation();return _vm.initializeSession($event)}}}):_vm._e()]},proxy:true}]),model:{value:(_vm.session),callback:function ($$v) {_vm.session=$$v},expression:"session"}})],1)],1)],1)]),_c('q-stepper-navigation',[_c('q-btn',{attrs:{"disable":!_vm.session.purposeId,"color":"primary","label":"Continue"},on:{"click":_vm.loadRequest}}),_c('q-btn',{staticClass:"q-ml-sm",attrs:{"flat":"","color":"primary","label":"Back"},on:{"click":_vm.goBack}})],1)],1),_vm._l((_vm.session.submissions),function(request,index){return _c('q-step',{key:index,attrs:{"name":request.name,"title":("Request name: " + (request.name ? request.name : '')),"prefix":index + 1,"color":"primary"}})}),_c('q-step',{attrs:{"name":3,"title":("Select request #" + _vm.requestNumber),"icon":"help"}},[(_vm.submission)?_c('div',{staticClass:"row q-col-gutter-sm items-center"},[(_vm.item && _vm.item.actionActionType)?_c('div',{staticClass:"col-12"},[_c('entity-select',{attrs:{"isRequired":true,"filled":"","label":"Request","provider":_vm.$service.providers.sessionRequests,"value":_vm.submission.requestId,"rules":[_vm.$rules.required],"oDataArgs":{
                columnFilters: [
                  {
                    column: 'ActionId',
                    op: 'equals',
                    value: _vm.item.actionActionType.action.id
                  }
                ]
              }},on:{"update:value":function($event){return _vm.$set(_vm.submission, "requestId", $event)}},model:{value:(_vm.submission.request),callback:function ($$v) {_vm.$set(_vm.submission, "request", $$v)},expression:"submission.request"}})],1):_vm._e(),_c('div',{staticClass:"col-12"},[_c('q-input',{attrs:{"label":"Name","filled":""},model:{value:(_vm.submission.name),callback:function ($$v) {_vm.$set(_vm.submission, "name", $$v)},expression:"submission.name"}})],1),_c('div',{staticClass:"col-12"},[_c('q-input',{attrs:{"filled":"","label":"Request Comments"},model:{value:(_vm.submission.requestComment),callback:function ($$v) {_vm.$set(_vm.submission, "requestComment", $$v)},expression:"submission.requestComment"}})],1),(_vm.submission.request)?_c('div',{staticClass:"col-12 q-pb-md"},[_c('dynamic-form',{attrs:{"questionnaireTitle":'Request Questionnaire',"questionnaire":_vm.requestQuestionnaire,"answers":_vm.requestAnswers},on:{"update:answers":function($event){_vm.requestAnswers=$event}}})],1):_vm._e(),(_vm.item.actionActionType)?_c('div',{staticClass:"col-12"},[_c('entity-select',{attrs:{"filled":"","label":"Next Step","provider":_vm.$service.providers.nextSteps,"value":_vm.submission.nextStepId,"oDataArgs":{
                columnFilters: [
                  {
                    column: 'ActionId',
                    op: 'equals',
                    value: _vm.item.actionActionType.action.id
                  }
                ]
              }},on:{"update:value":function($event){return _vm.$set(_vm.submission, "nextStepId", $event)}},model:{value:(_vm.submission.nextStep),callback:function ($$v) {_vm.$set(_vm.submission, "nextStep", $$v)},expression:"submission.nextStep"}})],1):_vm._e(),_c('div',{staticClass:"col-12"},[_c('q-input',{attrs:{"filled":"","label":"Next Step Comments"},model:{value:(_vm.submission.nextStepComment),callback:function ($$v) {_vm.$set(_vm.submission, "nextStepComment", $$v)},expression:"submission.nextStepComment"}})],1),(_vm.submission.nextStep)?_c('div',{staticClass:"col-12 q-pb-md"},[_c('dynamic-form',{attrs:{"questionnaireTitle":'Next Step Questionnaire',"questionnaire":_vm.nextStepQuestionnaire,"answers":_vm.nextStepAnswers},on:{"update:answers":function($event){_vm.nextStepAnswers=$event}}})],1):_vm._e(),_c('div',{staticClass:"col-12"},[_c('q-select',{attrs:{"filled":"","label":"Status","options":['Pending', 'Completed']},model:{value:(_vm.submission.status),callback:function ($$v) {_vm.$set(_vm.submission, "status", $$v)},expression:"submission.status"}})],1)]):_vm._e(),_c('q-stepper-navigation',[_c('q-btn',{attrs:{"color":"primary","label":"Finish"},on:{"click":_vm.saveItem}}),_c('q-btn',{staticClass:"q-ml-sm",attrs:{"color":"primary","label":"Add Request"},on:{"click":_vm.addRequest}}),_c('q-btn',{staticClass:"q-ml-sm",attrs:{"disable":_vm.requestNumber === 1,"flat":"","color":"primary","label":"Remove Request"},on:{"click":_vm.removeRequest}}),_c('q-btn',{staticClass:"q-ml-sm",attrs:{"flat":"","color":"primary","label":"Back"},on:{"click":_vm.goBack}})],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }