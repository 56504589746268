

































































































































































































































































































































































































































































































































import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import { ActionType } from "@/data/models/ActionTypes";
import EntitySelect from "@/components/EntitySelect.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import DatePicker from "@/components/DatePicker.vue";
import { NewQuestionnaireSubmission } from "@/data/models/Questionnaires";

@Component({
  components: {
    FormContainer,
    Loader,
    EntitySelect,
    DatePicker,
    DynamicForm
  }
})
export default class AddServiceWizard extends Vue {
  @Prop()
  public memberId!: string;
  @Prop()
  public action!: string;

  public provider!: DataProvider<any>;
  public ActionTypesProvider!: DataProvider<ActionType>;
  public householdMemberAction: any = [];
  private filteredServices: any = null;
  private userSubmissions: any = null;
  private houseHoldActions: any = null;
  private selectedHouseholdAction: any = null;
  private projectNames: any[] = [];
  private projectTitle: any = [];
  private item: any = null;
  private questionnaireId: any = null;
  private answers: any[] = [];
  private ActionTypesModel: any = null;
  private purposes: any[] = [];
  private requestAnswers: any[] = [];
  private nextStepAnswers: any[] = [];
  private nextStepQuestionnaire: any | null = null;
  private requestQuestionnaire: any | null = null;
  private session: any = [];
  private submission: any = [];
  private step: any = 1;
  private requestNumber = 1;
  private fullName: any = "";
  private existingActionSessions: any = null;
  private selectedSession: any = null;
  private selectedAction: any = [];
  private hasHrCompanies = false;
  private currentUser: any;  
  private actionTypeFilter = "";
  private existingActionsFilter = "";

  async created() {
    this.currentUser = await this.$service.providers.users.fetchItemAsync(this.$auth.userInfo.profile.sub);
    if (!this.$auth.permissions.admin.is) {
      if (!this.currentUser.isLawyer) {
        this.actionTypeFilter = "IsLegal ne true";
        this.existingActionsFilter = "ActionActionType/ActionType/IsLegal ne true";
      }
      if (!this.currentUser.isPsychologist) {
        this.actionTypeFilter += this.actionTypeFilter !== "" ? " and IsPsychological ne true" : "IsPsychological ne true";
        this.existingActionsFilter += this.existingActionsFilter !== "" ? " and ActionActionType/ActionType/IsPsychological ne true" : "ActionActionType/ActionType/IsPsychological ne true";
      }
    }
    if (!this.memberId) {
      return;
    }
    const member = await this.$service.providers.householdMembers.fetchItemAsync(
      this.memberId
    );
    this.fullName = member?.firstName + " " + member?.lastName;
    this.provider = this.$service.providers.actionActionTypes;
    this.ActionTypesProvider = this.$service.providers.actionTypes;
    this.userSubmissions = await this.$service.providers
      .householdMemberIntakeFormSubmissions(this.memberId)
      .fetchItemsAsync();

    this.projectNames = this.userSubmissions.items.filter((i: any) => {
      return !i.endDate;
    });

    this.projectNames = this.projectNames.map((i: any) => {
      return { label: i.project.title, value: i.project.id };
    });

    this.householdMemberAction = await this.$service.providers
      .householdMembersActions(this.memberId, false, this.existingActionsFilter)
      .fetchItemsAsync();

    this.houseHoldActions = this.householdMemberAction.items;

    this.filterActions();
  }

  @Watch("projectNames")
  onProjectNameChange() {
    if (this.projectNames.length < 2) {
      this.projectTitle = this.projectNames[0];
    }
  }

  @Watch("projectTitle")
  onProjectTitleChange() {
    this.item.projectId = this.projectTitle.value;
    this.filterActions();
    this.ActionTypesModel = null;
  }

  @Watch("ActionTypesModel", { immediate: true })
  async onActionTypesModelChanged() {
    if (!this.ActionTypesModel) {
      return;
    }
    if (this.$refs.select) {
      this.$nextTick(() => (this.$refs.select as any).loadOptions());
    }
  }

  @Watch("item.actionActionType")
  onActionActionTypeChange() {
    if (
      this.item &&
      this.item.actionActionType &&
      this.item.actionActionType.questionnaireId
    ) {
      this.questionnaireId = this.item.actionActionType.questionnaireId;
    }
    if (this.item.companies && this.item.companies.length > 0) {
      this.hasHrCompanies = true;
    }
  }

  @Watch("action", { immediate: true })
  onActionChanged() {
    if (!this.action) {
      this.initializeItem();
    } else {
      this.item = this.action;
    }
  }

  @Watch("submission.request")
  async onRequestChange() {
    if (
      !this.requestQuestionnaire ||
      this.submission.request?.questionnaireId !== this.requestQuestionnaire.id
    ) {
      this.requestQuestionnaire =
        this.submission.request && this.submission.request.questionnaireId
          ? await this.$service.providers.questionnaires.fetchItemAsync(
              this.submission.request.questionnaireId
            )
          : null;
    }
  }

  @Watch("submission.nextStep")
  async onNextStepChange() {
    if (
      !this.nextStepQuestionnaire ||
      this.submission.nextStep?.questionnaireId !==
        this.nextStepQuestionnaire.id
    ) {
      this.nextStepQuestionnaire =
        this.submission.nextStep && this.submission.nextStep.questionnaireId
          ? await this.$service.providers.questionnaires.fetchItemAsync(
              this.submission.nextStep.questionnaireId
            )
          : null;
    }
  }

  async bindHouseholdAction(e: any) {
    this.item = e;
    if (this.item.questionnaireSubmissionId) {
      const questionnaire = await this.$service.providers.questionnaireSubmissions.fetchItemAsync(
        this.item.questionnaireSubmissionId
      );
      this.item.questionnaireSubmission = questionnaire;
    }
    this.$forceUpdate();
  }

  filterActions() {
    if (this.houseHoldActions) {
      const houseHoldActions = this.householdMemberAction.items
        .filter((act: any) => act.projectId === this.item.projectId)
        .map((x: any) => {
          if (x.actionActionType !== null && x.actionActionType.action) {
            return x.actionActionType.action;
          }
        });
      this.filteredServices = houseHoldActions
        .map((x: any) => {
          if (!x.hasMultiples) {
            return `and Action/Name ne '${encodeURIComponent(x.name)}'`;
          }
        })
        .join(" ")
        .trim();
    }
  }

  clearSelectedAction() {
    this.selectedHouseholdAction = null;
    this.ActionTypesModel = null;
    this.initializeItem();
  }

  initializeItem() {
    this.item = {
      householdMemberId: this.memberId,
      actionActionTypeId: null,
      actionActionType: null,
      startDate: new Date().toISOString(),
      projectId: null,
      description: null,
      sessions: null,
      status: "Open",
      otherCompanies: "",
      companies: null
    };
  }

  initializeRequest() {
    this.submission = {
      name: "",
      requestId: null,
      request: null,
      requestComment: null,
      requestQuestionnaireSubmission: null,
      nextStepId: null,
      nextStep: null,
      nextStepComment: null,
      nextStepQuestionnaireSubmission: null,
      status: "Pending"
    };
  }

  initializeSession() {
    this.session = {
      date: new Date().toISOString(),
      purposeId: null,
      submissions: []
    };
  }

  async loadSessions() {
    if (this.selectedHouseholdAction) {
      this.existingActionSessions = (
        await this.$service.providers
          .householdMembersActionSessions(this.selectedHouseholdAction.id)
          .fetchItemsAsync()
      ).items;
    }

    this.initializeSession();

    if (!this.item.actionActionType) {
      return;
    }
    if (
      this.item.actionActionType &&
      !this.item.actionActionType.actionType.hasSessions
    ) {
      this.saveItem();
    } else {
      this.purposes = (
        await this.$service.providers.purposes.fetchItemsAsync({
          columnFilters: [
            {
              column: "ActionId",
              op: "Equals",
              value: `${this.item.actionActionType.actionId}`
            }
          ]
        })
      ).items;
      this.step = 2;
    }
  }

  async loadRequest() {
    if (!this.session.purposeId) {
      return;
    }
    this.initializeRequest();
    this.item.sessions = [];
    this.item.sessions.push(this.session);
    this.step = 3;
  }

  goBack() {
    if (this.step === 3) {
      this.session.submissions = [];
      this.item.sessions = [];
      this.requestNumber = 1;
      this.step = 2;
    } else if (this.step === 2) {
      this.existingActionSessions = null;
      this.selectedHouseholdAction = null;
      this.item.sessions = null;
      this.step = 1;
    } else {
      this.$router.go(-1);
    }
  }

  addRequest() {
    if (this.session && this.submission && this.submission.requestId) {
      this.createRequestSubmissions();
      this.session.submissions.push(this.submission);
      this.initializeRequest();
      this.step = 3;
      this.requestNumber += 1;
    } else {
      return;
    }
  }
  removeRequest() {
    if (this.requestNumber === 1) {
      return;
    }
    this.requestNumber -= 1;
    if (this.requestNumber === this.session.submissions.length) {
      this.submission = this.session.submissions[this.requestNumber - 1];
      this.session.submissions.pop();
    }
  }

  createRequestSubmissions() {
    if (this.submission.request && this.submission.request.questionnaireId) {
      this.submission.requestQuestionnaireSubmission = NewQuestionnaireSubmission(
        this.submission.request.questionnaireId,
        this.requestAnswers
      );
      this.submission.requestQuestionnaireSubmission.beneficiaryId = this.memberId;
    } else {
      this.submission.requestQuestionnaireSubmission = null;
    }
    if (this.submission.nextStep && this.submission.nextStep.questionnaireId) {
      this.submission.nextStepQuestionnaireSubmission = NewQuestionnaireSubmission(
        this.submission.nextStep.questionnaireId,
        this.nextStepAnswers
      );
      this.submission.nextStepQuestionnaireSubmission.beneficiaryId = this.memberId;
    } else {
      this.submission.nextStepQuestionnaireSubmission = null;
    }
  }

  setCompanies() {
    if (this.hasHrCompanies) {
      this.item.otherCompanies = "";
    } else {
      this.item.companies = null;
    }
  }

  saveItem() {
    this.setCompanies();
    if (!this.selectedHouseholdAction) {
      this.postItem();
    } else {
      this.putItem();
    }
  }

  async putItem() {
    if (this.session.purposeId && !this.submission.requestId) {
      this.$q.notify({
        message: "Request required",
        color: "red-4",
        timeout: 1000
      });
      return;
    } else {
      this.createRequestSubmissions();
      this.session.submissions.push(this.submission);
    }

    try {
      await this.$service.crud.householdMembersActions.saveAsync(this.item);
      this.$q.notify({
        message: "Item saved successfuslly!",
        color: "green-4",
        timeout: 2000
      });
    } catch {
      this.$q.notify({
        message: "Save failed!",
        color: "red-4",
        timeout: 2000
      });
    } finally {
      this.$router.go(-1);
    }
  }

  async postItem() {
    if (this.session.purposeId && !this.submission.requestId) {
      this.$q.notify({
        message: "Request required",
        color: "red-4",
        timeout: 1000
      });
      return;
    } else {
      this.createRequestSubmissions();
      this.session.submissions.push(this.submission);
    }

    if (this.questionnaireId) {
      this.item.questionnaireSubmission = NewQuestionnaireSubmission(
        this.questionnaireId,
        this.answers
      );
      this.item.questionnaireSubmission.beneficiaryId = this.memberId;
    }

    try {
      await this.$service.crud.householdMembersActions.addAsync([this.item]);
      this.$q.notify({
        message: "Item saved successfully!",
        color: "green-4",
        timeout: 2000
      });
    } catch {
      this.$q.notify({
        message: "Save failed!",
        color: "red-4",
        timeout: 2000
      });
    } finally {
      this.$router.go(-1);
    }
  }
}
