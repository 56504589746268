import { render, staticRenderFns } from "./DynamicForm.vue?vue&type=template&id=4b75371d&scoped=true&"
import script from "./DynamicForm.vue?vue&type=script&lang=ts&"
export * from "./DynamicForm.vue?vue&type=script&lang=ts&"
import style0 from "./DynamicForm.vue?vue&type=style&index=0&id=4b75371d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b75371d",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QField from 'quasar/src/components/field/QField.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QBtn,QInput,QBadge,QSelect,QIcon,QField,QOptionGroup});
